import axios from "axios";
import { baseURL } from "../config.js";

const path = "/agent_requests";

const instance = axios.create({
  baseURL: baseURL + path,
  headers: {},
});

export const requestAPI = {
  async create_request(authData, data) {
    const { access } = authData;
    const { request_status, comment_client, number_contract, currency_pay } =
      data;

    try {
      const response = await instance.post(
        `/create_request/`,
        { request_status, comment_client, number_contract, currency_pay },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error creating request:",
        err.response ? err.response.data : err
      );
      console.log("Failed to create request. Check console for details.");
    }
  },

  async get_request(authData, contractId, req = null) {
    const { access } = authData;

    const url = req
      ? `/get_request/${contractId}/?req=${req}`
      : `/get_request/${contractId}/`;

    try {
      const response = await instance.get(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting requests:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get requests. Check console for details.");
    }
  },
  async get__currency(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__currency/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting currency:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get currency. Check console for details.");
    }
  },
  async get__request_statuses(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__request_statuses/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting request statuses:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get request statuses. Check console for details.");
    }
  },

  async postfile(authData, formData) {
    const { access } = authData;

    try {
      const response = await instance.post(`/postfile/`, formData, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error posting file:",
        err.response ? err.response.data : err
      );
      console.log("Failed to post file. Check console for details.");
      throw err;
    }
  },
  async update__request(authData, data, id) {
    const { access } = authData;
    const { request_status, comment_client, total, invois, currency_pay } =
      data;

    try {
      const response = await instance.put(
        `/update__request/${id}/`,
        { request_status, comment_client, total, invois, currency_pay },
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error updating request:",
        err.response ? err.response.data : err
      );
      console.log("Failed to update request. Check console for details.");
    }
  },

  async get__request_doc_types(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get__request_doc_types/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting request doc types:",
        err.response ? err.response.data : err
      );
      console.log(
        "Failed to get request doc types. Check console for details."
      );
    }
  },

  async get_req_template(authData) {
    const { access } = authData;

    try {
      const response = await instance.get(`/get_req_template/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting request template:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get request template. Check console for details.");
    }
  },
};
