import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/authSlice";
import agentReducer from "./slices/agentSlice";
import contractReducer from "./slices/contractSlice";
import requestReducer from "./slices/requestSlice";

const agentPersistConfig = {
  key: "agent",
  storage,
  whitelist: ["companyId"],
};

const contractPersistConfig = {
  key: "contract",
  storage,
  whitelist: ["contractId"],
};

const persistedAgentReducer = persistReducer(agentPersistConfig, agentReducer);
const persistedContractReducer = persistReducer(
  contractPersistConfig,
  contractReducer
);

const rootReducer = combineReducers({
  auth: authReducer,
  agent: persistedAgentReducer,
  contract: persistedContractReducer,
  request: requestReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [],
      },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
