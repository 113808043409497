import styles from "./HomePage.module.scss";
import React, { useContext, useEffect, useState } from "react";
import BodyHeader from "../../components/BodyHeader/BodyHeaderProfile/BodyHeaderProfile";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import MainModal from "../../components/MainModal/MainModal";
import { Button, Divider } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { agentAPI } from "../../api/agentAPI";
import { useDispatch, useSelector } from "react-redux";
import WorkTwoToneIcon from "@mui/icons-material/WorkTwoTone";
import ModalsContext from "../../contexts/ModalsContext";
import {
  addProfiles,
  setCompany,
  setCompanyId,
  updateProfiles,
} from "../../redux/slices/agentSlice";
import {
  removeContractId,
  removeContractStatus,
} from "../../redux/slices/contractSlice";
import { ThreeCircles } from "react-loader-spinner";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import SnackbarSuccess from "../../reusables/SnackbarSuccess";
import { daDataAPI } from "../../api/daDataAPI";
import SnackbarFail from "../../reusables/SnackbarFail";
import { options } from "../../data/homePageOptions";
import { initialFormData, requiredFields } from "../../data/fields";

const HomePage = ({
  updateProfileStatusAndManagers,
  checkUpdate,
  managersUpdate,
  setCheckUpdate,
  setManagersUpdate,
  loading,
  setLoading,
}) => {
  const [loadingNew, setLoadingNew] = useState(false);
  const [innLoading, setInnLoading] = useState(false);
  const [bikLoading, setBikLoading] = useState(false);
  const [innModalLoading, setInnModalLoading] = useState(false);
  const [bikModalLoading, setBikModalLoading] = useState(false);

  const [managers_ilc, setManagers_ilc] = useState([]);
  const [managers_ilc_tp, setManagers_ilc_tp] = useState([]);

  const [checkCreate, setCheckCreate] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [managersCreate, setManagersCreate] = useState({
    manager_1: null,
    manager_2: null,
  });

  const [formData, setFormData] = useState(initialFormData);

  const company = useSelector((state) => state.agent.company);

  const [companyUpdate, setCompanyUpdate] = useState(
    company || initialFormData
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);

  const profiles = useSelector((state) => state.agent.profiles);
  const profileStatus = useSelector((state) => state.agent.profileStatus);
  const authData = useSelector((state) => state.auth.authData);
  const statuses = useSelector((state) => state.agent.profileStatuses);

  const dispatch = useDispatch();

  const {
    newProfileModalOpen,
    handleProfileModalOpen,
    handleProfileModalClose,
  } = useContext(ModalsContext);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const dataManagers = await agentAPI.get_managers(authData);
        if (dataManagers.statusText === "OK") {
          const managerIlc = dataManagers.data.filter(
            (item) => item.company === 1
          );
          const managerIlcTp = dataManagers.data.filter(
            (item) => item.company === 2
          );
          setManagers_ilc([
            ...managerIlc,
            {
              id: null,
              first_name: "Не назначен",
              last_name: "Не назначен",
              full_name: "Не назначен",
              id_hub: null,
              user: null,
              company: 1,
            },
          ]);
          setManagers_ilc_tp([
            ...managerIlcTp,
            {
              id: null,
              first_name: "Не назначен",
              last_name: "Не назначен",
              full_name: "Не назначен",
              id_hub: null,
              user: null,
              company: 2,
            },
          ]);
        }
      } catch (err) {
        console.error("Error fetching managers:", err);
      }
    };
    fetchManagers();
  }, [authData, dispatch, setLoading]);

  useEffect(() => {
    setCompanyUpdate(company || initialFormData);
  }, [company]);

  useEffect(() => {
    if (!checkCreate.firstCheck) {
      setManagersCreate((prev) => ({ ...prev, manager_1: null }));
    } else if (!checkCreate.secondCheck) {
      setManagersCreate((prev) => ({ ...prev, manager_2: null }));
    }
  }, [checkCreate]);

  useEffect(() => {
    if (!checkUpdate.firstCheck) {
      setCheckUpdate((prev) => ({ ...prev, manager_1: null }));
    } else if (!checkUpdate.secondCheck) {
      setCheckUpdate((prev) => ({ ...prev, manager_2: null }));
    }
  }, [checkUpdate, setCheckUpdate]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSuccess = () => {
    setSnackbarSuccessOpen(false);
  };
  const handleCloseFail = () => {
    setSnackbarFailOpen(false);
  };

  const handleChangeCompany = async (event) => {
    const selectedId = event.target.value;
    const selectedCompany = profiles.find(
      (profile) => profile.id === selectedId
    );
    dispatch(setCompany(selectedCompany));
    dispatch(setCompanyId(selectedCompany.id));
    dispatch(removeContractId());
    updateProfileStatusAndManagers(selectedCompany, statuses);
    dispatch(removeContractStatus());
  };

  const handleChangeManager = (event, managerKey, isModal) => {
    console.log(event.target.value);

    isModal
      ? setManagersCreate((prev) => ({
          ...prev,
          [managerKey]: event.target.value,
        }))
      : setManagersUpdate((prev) => ({
          ...prev,
          [managerKey]: event.target.value,
        }));
  };

  const handleChangeCheck = (event, isModal) => {
    console.log(event.target.checked);
    const check = event.target.checked;

    if (isModal) {
      setCheckCreate((prev) => ({
        ...prev,
        [event.target.name]: check,
      }));
      if (check)
        setFormData((prev) => ({ ...prev, comission: prev.comission - 0.25 }));
      else
        setFormData((prev) => ({ ...prev, comission: prev.comission + 0.25 }));
    } else {
      setCheckUpdate((prev) => ({
        ...prev,
        [event.target.name]: check,
      }));
    }
  };

  const handleFillFields = (event, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };

  const handleFillInn = async (isModal) => {
    let inn;
    if (isModal) {
      inn = formData.inn_company;
      if (inn) {
        try {
          setInnModalLoading(true);
          const response = await daDataAPI.getDataByINN(inn);
          const data = response.data;
          console.log("data", data);
          if (data) {
            if (data.suggestions.length > 0) {
              const firstOption = data.suggestions[0].data;
              setFormData((prev) => ({
                ...prev,
                full_name: firstOption.name.full_with_opf || "",
                address_company: firstOption.address.unrestricted_value || "",
                mail_address_company:
                  firstOption.address.unrestricted_value || "",
                kpp_company: firstOption.kpp || "",
                okpo_company: firstOption.okpo || "",
                okato_company: firstOption.okato || "",
                ogrn_company: firstOption.ogrn || "",
                okved_company: firstOption.okved || "",
                director_company: firstOption.management.name || "",
              }));
              setSnackbarSuccessOpen(true);
              setSnackbarText("Данные получены");
            } else {
              setSnackbarFailOpen(true);
              setSnackbarText("Введённые данные не верны");
            }
          }
          setInnModalLoading(false);
        } catch (error) {
          console.log(error.message);
          setInnModalLoading(false);
        }
      }
    } else {
      inn = companyUpdate.inn_company;
      if (inn) {
        try {
          setInnLoading(true);
          const response = await daDataAPI.getDataByINN(inn);
          const data = response.data;
          if (data) {
            if (data.suggestions.length > 0) {
              const firstOption = data.suggestions[0].data;
              setCompanyUpdate((prev) => ({
                ...prev,
                full_name: firstOption.name.full_with_opf || "",
                address_company: firstOption.address.unrestricted_value || "",
                mail_address_company:
                  firstOption.address.unrestricted_value || "",
                kpp_company: firstOption.kpp || "",
                okpo_company: firstOption.okpo || "",
                okato_company: firstOption.okato || "",
                ogrn_company: firstOption.ogrn || "",
                okved_company: firstOption.okved || "",
                director_company: firstOption.management.name || "",
              }));
              setSnackbarSuccessOpen(true);
              setSnackbarText("Данные получены");
            } else {
              console.log("Введённые данные не верны");

              setSnackbarFailOpen(true);
              setSnackbarText("Введённые данные не верны");
            }
          }
          setInnLoading(false);
        } catch (error) {
          console.log(error.message);
          setInnLoading(false);
        }
      }
    }
  };

  const handleFillBik = async (isModal) => {
    let bik;
    if (isModal) {
      bik = formData.bik_company;
      if (bik) {
        try {
          setBikModalLoading(true);
          const response = await daDataAPI.getDataByBIK(bik);
          const data = response.data;
          console.log("data", data);
          if (data) {
            if (data.suggestions.length > 0) {
              const firstOption = data.suggestions[0].data;
              setFormData((prev) => ({
                ...prev,
                name_bank: firstOption.name.payment || "",
                korrespondent_company: firstOption.correspondent_account || "",
              }));
              setSnackbarSuccessOpen(true);
              setSnackbarText("Данные получены");
            } else {
              setSnackbarFailOpen(true);
              setSnackbarText("Введённые данные не верны");
            }
          }
          setBikModalLoading(false);
        } catch (error) {
          console.log(error.message);
          setBikModalLoading(false);
        }
      }
    } else {
      bik = companyUpdate.bik_company;
      if (bik) {
        try {
          setBikLoading(true);
          const response = await daDataAPI.getDataByBIK(bik);
          const data = response.data;
          if (data) {
            if (data.suggestions.length > 0) {
              const firstOption = data.suggestions[0].data;
              setCompanyUpdate((prev) => ({
                ...prev,
                name_bank: firstOption.name.payment || "",
                korrespondent_company: firstOption.correspondent_account || "",
              }));
              setSnackbarSuccessOpen(true);
              setSnackbarText("Данные получены");
            } else {
              setSnackbarFailOpen(true);
              setSnackbarText("Введённые данные не верны");
            }
          }
          setBikLoading(false);
        } catch (error) {
          console.log(error.message);
          setBikLoading(false);
        }
      }
    }
  };

  const handleChangeFields = (event, fieldName) => {
    setCompanyUpdate({
      ...companyUpdate,
      [fieldName]: event.target.value,
    });
  };

  const handleCreateProfile = async () => {
    const hasEmptyFields = requiredFields.some((field) => !formData[field]);

    if (hasEmptyFields) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, заполните все обязательные поля!");
      return;
    }

    // if (checkCreate.firstCheck && !managersCreate.manager_1) {
    //   setSnackbarOpen(true);
    //   setSnackbarText("Пожалуйста, укажите менеджера ИЛС!");
    //   return;
    // }
    // if (checkCreate.secondCheck && !managersCreate.manager_2) {
    //   setSnackbarOpen(true);
    //   setSnackbarText("Пожалуйста, укажите менеджера ИЛС ТП!");
    //   return;
    // }

    let dataToDB = formData;
    dataToDB = {
      ...formData,
      pay_profile_status: 1,
      work_ilc: checkCreate.firstCheck,
      manager_ilc: managersCreate.manager_1,
      work_ilc_tp: checkCreate.secondCheck,
      manager_tp: managersCreate.manager_2,
      //agent_success: true,
    };

    try {
      setLoadingNew(true);
      const data = await agentAPI.create_agent_profile(dataToDB, authData);

      if (data.statusText === "Created") {
        const newCompany = data.data;
        dispatch(addProfiles(newCompany));
        dispatch(setCompany(newCompany));
        dispatch(setCompanyId(newCompany.id));
        dispatch(removeContractId());
        dispatch(removeContractStatus());
        updateProfileStatusAndManagers(newCompany, statuses);
        handleProfileModalClose();
        setFormData(initialFormData);
      }
      setLoadingNew(false);
    } catch (error) {
      console.log(error.message);
      setLoadingNew(false);
    }
  };

  const handleUpdateProfile = async () => {
    setLoading(true);

    const hasEmptyFields = requiredFields.some(
      (field) => !companyUpdate[field]
    );

    if (hasEmptyFields) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, заполните все обязательные поля!");
      setLoading(false);
      return;
    }
    // if (checkUpdate.firstCheck && !managersUpdate.manager_1) {
    //   setSnackbarOpen(true);
    //   setSnackbarText("Пожалуйста, укажите менеджера ИЛС!");
    //   setLoading(false);
    //   return;
    // }
    // if (checkUpdate.secondCheck && !managersUpdate.manager_2) {
    //   setSnackbarOpen(true);
    //   setSnackbarText("Пожалуйста, укажите менеджера ИЛС ТП!");
    //   setLoading(false);
    //   return;
    // }

    let dataToDB = company;
    dataToDB = {
      ...companyUpdate,
      pay_profile_status: 1,
      work_ilc: checkUpdate.firstCheck,
      manager_ilc: managersUpdate.manager_1,
      work_ilc_tp: checkUpdate.secondCheck,
      manager_tp: managersUpdate.manager_2,
      // agent_success: true,
    };
    try {
      const data = await agentAPI.update_agent_profile(dataToDB, authData);
      if (data.statusText === "OK") {
        const updatedCompany = data.data;
        dispatch(updateProfiles(updatedCompany));
        updateProfileStatusAndManagers(updatedCompany, statuses);
      }
      setSnackbarSuccessOpen(true);
      setSnackbarText("Профиль обновлен");
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <BodyHeader
        chosenOption={company}
        onChangeFunc={handleChangeCompany}
        list={profiles.length > 0 ? profiles : null}
        handleOpen={handleProfileModalOpen}
        text="Выберите компанию"
        buttonText="Новая компания"
        profileStatus={profileStatus}
        loading={loading}
      />
      <SnackbarSuccess
        snackbarOpen={snackbarSuccessOpen}
        handleClose={handleCloseSuccess}
        snackbarText={snackbarText}
      />
      <SnackbarFail
        snackbarOpen={snackbarFailOpen}
        handleClose={handleCloseFail}
        snackbarText={snackbarText}
      />
      {loading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(33, 150, 243, 0.9)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : profiles.length > 0 ? (
        <ProfileInfo
          options={options}
          managers_1={managers_ilc}
          managers_2={managers_ilc_tp}
          check={checkUpdate}
          managers={managersUpdate}
          data={companyUpdate}
          handleChangeManager={handleChangeManager}
          handleChangeCheck={handleChangeCheck}
          handleChangeFields={handleChangeFields}
          profileStatus={profileStatus}
          handleFillInn={handleFillInn}
          handleFillBik={handleFillBik}
          innLoading={innLoading}
          bikLoading={bikLoading}
        />
      ) : (
        <div className={styles.warnText}>
          <WorkTwoToneIcon className={styles.rotatingIcon} />
          <span>Добавьте новую компанию</span>
        </div>
      )}

      <MainModal open={newProfileModalOpen} onClose={handleProfileModalClose}>
        <div className={styles.modalheader}>
          <h2>Профиль компании</h2>
          <div onClick={handleProfileModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />

        <ProfileInfo
          options={options}
          managers_1={managers_ilc}
          managers_2={managers_ilc_tp}
          check={checkCreate}
          managers={managersCreate}
          data={formData}
          handleChangeManager={handleChangeManager}
          handleChangeCheck={handleChangeCheck}
          handleChangeFields={handleFillFields}
          isModal
          handleFillInn={handleFillInn}
          handleFillBik={handleFillBik}
          innModalLoading={innModalLoading}
          bikModalLoading={bikModalLoading}
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
            }}
            onClick={handleCreateProfile}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
      </MainModal>

      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />

      {profiles.length > 0 && (
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
            }}
            onClick={handleUpdateProfile}
            disabled={profileStatus?.id === 3 && true}
          >
            {loading ? <DotsSpinner /> : "Обновить"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomePage;
