import RequestsTable from "../../components/RequestsTable/RequestsTable";
import styles from "./RequestsPage.module.scss";
import { useContext, useEffect, useState } from "react";
import Request from "../../components/Request/Request";
import MainModal from "../../components/MainModal/MainModal";
import { IoMdClose } from "react-icons/io";
import { Button, Divider } from "@mui/material";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { requestAPI } from "../../api/requestAPI";
import {
  addRequests,
  setCurrencies,
  setRequestDocTypes,
  setRequests,
  setRequestsStatuses,
  updateRequests,
} from "../../redux/slices/requestSlice";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalsContext from "../../contexts/ModalsContext";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import { ThreeCircles } from "react-loader-spinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import SnackbarSuccess from "../../reusables/SnackbarSuccess";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import SnackbarFail from "../../reusables/SnackbarFail";
import {
  headers,
  newRequestOptions,
  requestOptions,
} from "../../data/requestsPageOptions";
import { statusConfig } from "../../data/requestsConfig";

const RequestsPage = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);

  const [requestLoading, setRequestLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [loadingNew, setLoadingNew] = useState(false);

  const [newRequestData, setNewRequestData] = useState({
    request_status: 1,
    comment_client: "",
    currency_pay: null,
  });

  const contract = useSelector((state) => state.contract.contract);
  const authData = useSelector((state) => state.auth.authData);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const requests = useSelector((state) => state.request.requests);
  const requestStatuses = useSelector((state) => state.request.requestStatuses);
  const currencies = useSelector((state) => state.request.currencies);
  const requestDocTypes = useSelector((state) => state.request.requestDocTypes);
  const contractStatus = useSelector((state) => state.contract.contractStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleRequesttModalOpen,
    handleRequestModalClose,
    newRequestModalOpen,
  } = useContext(ModalsContext);

  const [searchParams] = useSearchParams();
  const req_id = searchParams.get("req_id");

  useEffect(() => {
    if (req_id && contract && currencies && requestStatuses) {
      const fetchRequest = async () => {
        try {
          setPageLoading(true);
          const response = await requestAPI.get_request(
            authData,
            contract.id,
            parseInt(req_id)
          );
          if (response) {
            if (response.statusText === "OK") {
              const requestData = response.data;
              const updatedNewRequest = updateRequest(
                requestData,
                requestStatuses,
                currencies
              );
              setSelectedRequest(updatedNewRequest);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } catch (err) {
          console.log(err);
          setPageLoading(false);
        }
      };
      fetchRequest();
    }
  }, [req_id, contract, currencies, requestStatuses, authData, dispatch]);

  // Fetching requests and request-related data
  useEffect(() => {
    if (isAuth && contract) {
      const fetchRequests = async () => {
        try {
          let statuses;
          let currencies;

          const dataCurrency = await requestAPI.get__currency(authData);
          if (dataCurrency.statusText === "OK") {
            const pickedCurrencies = ["RUB", "USD", "EUR", "CNY"];
            currencies = dataCurrency.data.filter((currency) =>
              pickedCurrencies.includes(currency.currency_title)
            );
            dispatch(setCurrencies(currencies));
          }

          const dataStatuses = await requestAPI.get__request_statuses(authData);
          if (dataStatuses.statusText === "OK") {
            statuses = dataStatuses.data;
            dispatch(setRequestsStatuses(statuses));
          }

          const response = await requestAPI.get_request(authData, contract.id);
          if (response.statusText === "OK") {
            if (response.data.length === 0) {
              handleRequesttModalOpen();
            } else if (response.data.length > 0 && newRequestModalOpen) {
              handleRequestModalClose();
            }
            const requestsData = response.data.map((request) =>
              updateRequest(request, statuses, currencies)
            );
            requestsData.sort((a, b) => a.number_request - b.number_request);

            dispatch(setRequests(requestsData));
          }
          const responseDocTypes = await requestAPI.get__request_doc_types(
            authData
          );
          if (responseDocTypes.statusText === "OK") {
            dispatch(setRequestDocTypes(responseDocTypes.data));
          }
        } catch (err) {
          console.error("Error fetching requests:", err);
        } finally {
          setPageLoading(false);
        }
      };
      fetchRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, authData, contract, dispatch]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSuccess = () => {
    setSnackbarSuccessOpen(false);
  };

  const handleCloseFail = () => {
    setSnackbarFailOpen(false);
  };

  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  const updateRequest = (request, statuses, currencies) => {
    const foundStatus = statuses.find(
      (status) => status.id === request.request_status
    );
    const foundCurrency = currencies.find(
      (currency) => currency.id === request.currency_pay
    );
    return {
      ...request,
      status: foundStatus.status_title,
      currency: foundCurrency?.currency_title,
      request_date: moment(request.request_date).format("DD.MM.YYYY"),
      total: request.total && parseInt(request.total, 10),
    };
  };

  const goBackToRequests = () => {
    setSelectedRequest(null);
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };

  const handleFileUpload = async (event, docType) => {
    setFileLoading(true);

    const file = event.target.files[0];

    const formData = new FormData();

    if (file) {
      if (file.size > 50000000) {
        setSnackbarFailOpen(true);
        setSnackbarText("Размер файла превышает 50 МБ");
        setFileLoading(false);
        return;
      }
      try {
        console.log("requestDocTypes", requestDocTypes);

        const foundDocType = requestDocTypes.find(
          (item) => item.id === docType
        );

        formData.append("req_file", file);
        formData.append("request", selectedRequest.id);
        formData.append("file_name", file.name);
        formData.append("file_type", file.type);
        formData.append("doc_type", foundDocType.id);

        const response = await requestAPI.postfile(authData, formData);
        if (response.statusText === "Created") {
          const updatedRequest = {
            ...selectedRequest,
            files: [...selectedRequest.files, response.data],
          };
          setSelectedRequest(updatedRequest);
        }
        setFileLoading(false);
      } catch (err) {
        console.log("Ошибка при загрузке файла:", err);
        setSnackbarFailOpen(true);
        setSnackbarText("Ошибка при загрузке файла");
        setFileLoading(false);
      }
    }
  };

  const handleChangeInputs = (event, field) => {
    let value = event.target.value;

    setNewRequestData({
      ...newRequestData,
      [field]: value,
    });
  };

  const handleChangeUpdateInputs = (event, field) => {
    let value = event.target.value;

    setSelectedRequest({
      ...selectedRequest,
      [field]: value,
    });
  };

  const handleRowClick = async (row) => {
    console.log("row", row);

    try {
      setPageLoading(true);
      const response = await requestAPI.get_request(
        authData,
        contract.id,
        row.id
      );
      if (response.statusText === "OK") {
        const requestData = response.data;
        const updatedNewRequest = updateRequest(
          requestData,
          requestStatuses,
          currencies
        );
        setSelectedRequest(updatedNewRequest);
      }
      setPageLoading(false);
    } catch (err) {
      console.log(err);
      setPageLoading(false);
    }
  };

  const handleCreateRequest = async () => {
    const dataToDB = {
      ...newRequestData,
      number_contract: contract.id,
    };

    if (!newRequestData.currency_pay) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, укажите валюту");
      return;
    }
    try {
      setLoadingNew(true);
      const data = await requestAPI.create_request(authData, dataToDB);

      if (data.statusText === "Created") {
        const newRequest = data.data;
        const updatedNewRequest = updateRequest(
          newRequest,
          requestStatuses,
          currencies
        );
        dispatch(addRequests(updatedNewRequest));
        handleRequestModalClose();
      }
      setLoadingNew(false);
    } catch (error) {
      console.log(error.message);
      setLoadingNew(false);
    }
  };

  const handleUpdateRequest = async () => {
    const config = statusConfig[selectedRequest.request_status];

    if (selectedRequest.request_status === 2 && !selectedRequest.total) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, укажите сумму");
      return;
    }

    const dataToDB = {
      request_status: config.nextStatus,
      comment_client: selectedRequest.comment_client,
      total: selectedRequest.total,
      invois: selectedRequest.invois,
      currency_pay: selectedRequest.currency_pay,
    };

    try {
      setRequestLoading(true);
      const data = await requestAPI.update__request(
        authData,
        dataToDB,
        selectedRequest.id
      );

      if (data.statusText === "OK") {
        const updatedRequest = data.data;
        const updatedNewRequest = updateRequest(
          updatedRequest,
          requestStatuses,
          currencies
        );
        setSelectedRequest(updatedNewRequest);
        dispatch(updateRequests(updatedNewRequest));
      }
      setSnackbarSuccessOpen(true);
      setRequestLoading(false);
    } catch (error) {
      console.log(error.message);
      setRequestLoading(false);
    }
  };

  if (contractStatus) {
    if (contractStatus.id !== 7) {
      return <Navigate to="/contracts" />;
    }
  }

  return (
    <div className={styles.container}>
      {!selectedRequest && contract && (
        <div className={styles.headerText}>
          <div className={styles.text}>Плательщик</div>
          <div className={styles.payer}>{contract.agent_title}</div>
          <div className={styles.text}>Контракт</div>
          <div className={styles.payer}>{contract.contract_number}</div>
        </div>
      )}

      <SnackbarSuccess
        snackbarOpen={snackbarSuccessOpen}
        handleClose={handleCloseSuccess}
        snackbarText="Заявка обновлена"
      />
      <SnackbarFail
        snackbarOpen={snackbarFailOpen}
        handleClose={handleCloseFail}
        snackbarText={snackbarText}
      />

      <div className={styles.buttonContainer}>
        {selectedRequest && (
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{
              // minWidth: "140px",
              minHeight: "40px",
              color: "rgba(28, 107, 31, 0.7)",
              borderColor: "rgba(28, 107, 31, 0.7)",
              "&:hover": {
                color: "rgba(28, 107, 31, 0.8)",
                borderColor: "rgba(28, 107, 31, 0.7)",
              },
            }}
            onClick={goBackToRequests}
          >
            Вернуться к заявкам
          </Button>
        )}
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{
            // minWidth: "140px",
            minHeight: "40px",
            backgroundColor: selectedRequest ? "rgba(28, 107, 31, 0.7)" : null,
            "&:hover": {
              backgroundColor: selectedRequest
                ? "rgba(28, 107, 31, 0.8)"
                : null,
            },
          }}
          onClick={handleRequesttModalOpen}
          disabled={pageLoading}
        >
          Новая заявка
        </Button>
      </div>

      {pageLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(33, 150, 243, 0.9)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        requests.length > 0 &&
        !selectedRequest && (
          <RequestsTable
            headers={headers}
            contract={contract}
            payer={contract.agent_title}
            rows={requests}
            handleRowClick={handleRowClick}
          />
        )
      )}
      {!selectedRequest && requests.length === 0 && !pageLoading && (
        <div className={styles.warnText}>
          <CurrencyExchangeIcon className={styles.rotatingIcon} />
          <span>Добавьте новую заявку</span>
        </div>
      )}

      {requestLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(28, 107, 31, 0.7)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        selectedRequest &&
        !pageLoading && (
          <Request
            headers={requestOptions}
            item={selectedRequest}
            contract={contract}
            handleOpen={handleRequesttModalOpen}
            currencies={currencies}
            handleFileUpload={handleFileUpload}
            triggerFileInput={triggerFileInput}
            requestLoading={requestLoading}
            fileLoading={fileLoading}
            handleChangeUpdateInputs={handleChangeUpdateInputs}
            handleUpdateRequest={handleUpdateRequest}
            statusConfig={statusConfig}
            requestDocTypes={requestDocTypes}
          />
        )
      )}

      <MainModal open={newRequestModalOpen} onClose={handleRequestModalClose}>
        <div className={styles.modalheader}>
          <h2>Новая заявка</h2>
          <div onClick={handleRequestModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal
          options={newRequestOptions}
          handleChangeInputs={handleChangeInputs}
          currencies={currencies}
          newData={newRequestData}
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(38, 45, 137, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(38, 45, 137, 0.7)",
              },
            }}
            onClick={handleCreateRequest}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
      </MainModal>
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default RequestsPage;
