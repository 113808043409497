import styles from "./EmailForResetPage.module.scss";
import { imgURL } from "../../config";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { authAPI } from "../../api/authAPI";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";

const EmailForResetPage = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const handleCloseSnack = () => {
    setSnackbarOpen(false);
  };

  const isAuth = useSelector((state) => state.auth.isAuth);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите ваш email");
      return;
    }
    try {
      setLoading(true);
      const data = await authAPI.send_email(email);
      console.log("data", data);

      if (data.statusText === "OK") {
        handleOpen();
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.imgContainer}>
          <img alt="logo" src={imgURL} />
        </div>
        <div className={styles.content}>
          <h1>Платежный агент</h1>
          <div className={styles.form}>
            <div className={styles.inputContainer}>
              <span className={styles.spanText}>
                Введите адрес вашей электронной почты, и мы отправим вам ссылку
                для сброса пароля.
              </span>
              <input
                className={styles.input}
                placeholder="Введите свой email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <button className={styles.buttonLogin} type="submit">
                {loading ? (
                  <DotsSpinner auth />
                ) : (
                  <span className={styles.spanText2}>Отправить</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className={styles.modal}>
            <div className={styles.modalContent}>
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Ссылка отправлена
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                Ссылка для сброса пароля была отправлена на ваш email.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/auth")}
                sx={{}}
              >
                Закрыть
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <SnackbarWarning
        handleClose={handleCloseSnack}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default EmailForResetPage;
