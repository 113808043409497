export const statusConfig = {
  // Запрос курса
  1: {
    requiredFields: [""],
    disabled: ["currency_pay"],
    nextStatus: 1,
    text: "",
  },
  // Курс согласован
  2: {
    requiredFields: ["total"],
    disabled: ["currency_pay"],
    nextStatus: 3,
    text: "Курс согласован. Заполните заявку, подпишите, приложите в файлы заявку в word и подписанную в pdf, после этого нажмите на кнопку «Отправить заявку»",
  },
  // Заявка на подписании
  3: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 3,
    text: "",
  },
  // Заявка подписана
  4: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 4,
    text: "Подгружена заявка, подписанная банком.",
  },
  // Счет на оплату
  5: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 5,
    text: "Оплатите счет и подгрузите к заявке платежки, нажмите обновить.",
  },
  // Оплата поставщику в процессе
  6: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 6,
    text: "Банк подтвердил получение платежа.",
  },
  // Оплата поставщику произведена
  7: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 7,
    text: "Получен SWIFT и платежка от банка.",
  },
  // Поставщик получил средства
  8: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 8,
    text: "Поставщик получил средства.",
  },
  // Агентский отчет на подписании
  9: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 9,
    text: "Подпишите агентский отчет и подгрузите к заявке, нажмите обновить.",
  },
  // Услуга завершена
  10: {
    requiredFields: [""],
    disabled: ["currency_pay", "total", "invois", "comment_client"],
    nextStatus: 10,
    text: "",
  },
  // Возврат средств
  11: {
    requiredFields: [""],
    disabled: ["currency_pay", "total"],
    nextStatus: 11,
    text: "",
  },
  // Отменена
  12: {
    requiredFields: [""],
    disabled: ["currency_pay", "total", "invois", "comment_client"],
    nextStatus: 12,
    text: "",
  },
};
