import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { CiLogout } from "react-icons/ci";
import { setIsAuth } from "../../redux/slices/authSlice";
import { authAPI } from "../../api/authAPI";
import Cookies from "js-cookie";
import {
  removeCompanyId,
  removeProfiles,
  removeProfileStatus,
} from "../../redux/slices/agentSlice";
import {
  removeContractId,
  removeContracts,
  removeContractStatus,
} from "../../redux/slices/contractSlice";
import {
  removeRequests,
  removeRequestsStatuses,
} from "../../redux/slices/requestSlice";
import { useState } from "react";
import Spinner from "../Loaders/Spinner/Spinner";
import Frame2 from "../../assets/icons/Frame2.png";

const Header = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const authData = useSelector((state) => state.auth.authData);

  const handleLogout = async () => {
    try {
      setLoading(true);
      const response = await authAPI.logout(authData);

      if (response.status === 205) {
        Cookies.remove("authData");
        dispatch(setIsAuth(false));
        dispatch(removeProfiles());
        dispatch(removeCompanyId());
        dispatch(removeProfileStatus());
        dispatch(removeContractStatus());
        dispatch(removeContracts());
        dispatch(removeContractId());
        dispatch(removeRequests());
        dispatch(removeRequestsStatuses());
      } else {
        console.log(`Unexpected status code: ${response.status}`);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <header className={styles.header}>
      <button onClick={handleLogout}>
        {loading ? <Spinner /> : <CiLogout className={styles.logout} />}
      </button>
      <div className={styles.imageContainer}>
        <img src={Frame2} alt="" />
        <div className={styles.text}>Платежный агент ILC</div>
      </div>
      <div
        className={styles.name}
      >{`${authData.first_name} ${authData.last_name}`}</div>
    </header>
  );
};

export default Header;
