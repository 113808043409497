import React from "react";
import styles from "./InfoComponent.module.scss";
import { TextField } from "@mui/material";
import ReusableChip from "../ReusableChip/ReusableChip";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import DotsSpinner from "../Loaders/DotsSpinner/DotsSpinner";
import { getFileIcon } from "../../services/getFileIcon";
import { getContractStatusStyles } from "../../services/getContractStatusStyles";
import { useEffect } from "react";

const InfoComponent = ({
  options,
  text,
  contractStatus,
  company,
  contract,
  handleChangeComment,
  clientComment,
  triggerFileInput,
  handleFileUpload,
  fileLoading,
  contractDocTypes,
}) => {
  const otherFiles = contract.files.filter((file) => file.doc_type === 11);

  useEffect(() => {
    console.log("otherFiles", otherFiles);
  }, [otherFiles]);

  return (
    <div className={styles.body}>
      <h2>{text}</h2>
      {options.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles.element}>
            <div className={styles.name}>
              <span>{item.label}</span>
            </div>
            {item.type === "Chip" && (
              <div className={styles.field}>
                {item.name === "Status" &&
                  contractStatus &&
                  (() => {
                    const styles = getContractStatusStyles(contractStatus.id);
                    if (!styles) return null;
                    return (
                      <ReusableChip
                        borderColor={styles.borderColor}
                        backgroundColor={styles.backgroundColor}
                        color={styles.color}
                        label={contractStatus.status_title}
                        minHeight="45px"
                        minWidth="100px"
                      />
                    );
                  })()}
                {item.name === "is_vbk" && (
                  <ReusableChip
                    borderColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.5)"
                        : "rgba(33, 150, 243, 0.5)"
                    }
                    backgroundColor={
                      contract.is_vbk
                        ? "rgba(76, 175, 80, 0.1)"
                        : "rgba(33, 150, 243, 0.1)"
                    }
                    color={
                      contract.is_vbk
                        ? "rgba(28, 107, 31, 0.8)"
                        : "rgba(0, 81, 148, 0.8)"
                    }
                    label={contract.is_vbk ? "Да" : "Нет"}
                    minHeight="45px"
                    minWidth="100px"
                  />
                )}
              </div>
            )}
            {item.name === "TextCompany" && (
              <div className={styles.field}>{company.full_name}</div>
            )}
            {item.name === "TextManagerComment" && (
              <div className={styles.field}>{contract.comment || "---"}</div>
            )}
            {item.name === "TextContractNumber" && (
              <div className={styles.field}>{contract.contract_number}</div>
            )}
            {item.type === "TextField" && (
              <div className={styles.field}>
                <TextField
                  label={item.text}
                  sx={{
                    width: "100%",
                    "& .MuiInputLabel-root": {
                      color: "#757575",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#757575",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#757575",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#757575",
                    },
                  }}
                  value={clientComment}
                  // InputLabelProps={{
                  //   shrink: !!contract.comment_client,
                  // }}
                  onChange={handleChangeComment}
                  disabled={
                    (contractStatus?.id === 5 ||
                      contractStatus?.id === 6 ||
                      contractStatus?.id === 7) &&
                    true
                  }
                />
              </div>
            )}
          </div>
          <div className={styles.line} />
        </React.Fragment>
      ))}
      <React.Fragment>
        {contract.files?.length > 0 ? (
          <>
            {contract.files.map(
              (item, index) =>
                item.doc_type !== 11 && (
                  <>
                    <div className={styles.element} key={index}>
                      <div className={styles.name}>
                        <span>
                          {contractDocTypes.find(
                            (type) => type.id === item.doc_type
                          )?.type_title || "Файлы"}
                        </span>
                      </div>
                      <div className={styles.filesContainer}>
                        <FilesCard getFileIcon={getFileIcon} item={item} />
                      </div>
                    </div>
                    <div className={styles.line} />
                  </>
                )
            )}
            {otherFiles.length > 0 && (
              <div className={styles.element}>
                <div className={styles.name}>
                  <span>Другое</span>
                </div>
                <div className={styles.filesContainer}>
                  {otherFiles.map((item, index) => (
                    <FilesCard
                      item={item}
                      getFileIcon={getFileIcon}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.element}>
            <div className={styles.name}>
              <span>Файлы</span>
            </div>
            <div className={styles.filesContainer}>Нет загруженных файлов</div>
          </div>
        )}
        <div className={styles.line} />
      </React.Fragment>
      <React.Fragment>
        <div className={styles.element}>
          <div className={styles.name}>
            <span>Выбрать другие файлы</span>
          </div>
          <div className={styles.field}>
            <button
              className={` ${
                contractStatus?.id === 5 ||
                contractStatus?.id === 6 ||
                contractStatus?.id === 7
                  ? styles.buttonDisabled
                  : styles.uploadButton
              }`}
              onClick={() => triggerFileInput("OtherFiles")}
              disabled={
                (contractStatus?.id === 5 ||
                  contractStatus?.id === 6 ||
                  contractStatus?.id === 7) &&
                true
              }
            >
              {fileLoading ? (
                <DotsSpinner />
              ) : (
                <>
                  <span>Выбрать файлы</span>
                  <GrAttachment size={20} />
                </>
              )}
            </button>
            <input
              id="OtherFiles"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

const FilesCard = ({ getFileIcon, item }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.icon}>{getFileIcon(item.file_type)}</div>
        <span className={styles.text}>{item.file_name}</span>
      </div>
      <button className={styles.downloadButton}>
        <a
          href={`https://shev-taras.com${item.contr_file}`}
          download={item.file_name}
          target={
            [
              "application/pdf",
              "image/jpeg",
              "image/png",
              "image/svg+xml",
              "image/heic",
              "image/heif",
            ].includes(item.file_type)
              ? "_blank"
              : "_self"
          }
          rel="noreferrer"
          className={styles.downloadLink}
        >
          <FaCloudDownloadAlt size={20} />
        </a>
      </button>
    </div>
  );
};

export default InfoComponent;
