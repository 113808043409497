export const headers = [
  { label: "Номер заявки", name: "number_request", type: "Text", text: "" },
  { label: "Дата заявки", name: "request_date", type: "Text", text: "" },
  {
    label: "Статус заявки",
    name: "status",
    type: "Chip",
    text: "",
  },
  {
    label: "Сумма",
    name: "total",
    type: "TextFieldNumber",
    text: "Введите сумму",
  },
  { label: "Валюта", name: "currency_pay", type: "Select", text: "" },
  { label: "Согласованный курс", name: "curs", type: "Text", text: "" },
];

export const requestOptions = [
  ...headers,
  { label: "Комментарий менеджера", name: "comment", type: "Text", text: "" },
  {
    label: "Комментарий клиента",
    name: "comment_client",
    type: "TextField",
    text: "Комментарий клиента",
  },
  {
    label: "Инвойс от поставщика",
    name: "invois",
    type: "TextField",
    text: "Инвойс от поставщика",
  },
];

export const newRequestOptions = [
  {
    label: "Контракт",
    type: "Text",
    name: "contract_name",
    text: "839848239489834849849'",
  },
  {
    label: "Статус заявки",
    type: "Chip",
    text: "Запрос курса",
    name: "request_status",
  },
  {
    label: "Валюта",
    type: "Select",
    text: "RUB",
    name: "currency_pay",
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
    name: "comment_client",
  },
];
