import { fileIcons } from "./fileIcons";

export const getFileIcon = (fileType) => {
  if (fileType.includes("pdf")) return fileIcons.pdf;
  if (
    fileType.includes("msword") ||
    fileType.includes("officedocument.wordprocessingml.document")
  )
    return fileIcons.word;
  if (fileType.includes("spreadsheet")) return fileIcons.excel;
  if (fileType.includes("image")) return fileIcons.image;
  return fileIcons.unknown;
};
