import styles from "./LoginPage.module.scss";
import { imgURL } from "../../config";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData, setIsAuth } from "../../redux/slices/authSlice";
import { useState } from "react";
import { authAPI } from "../../api/authAPI";
import Cookies from "js-cookie";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.isAuth);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите ваш email");
      return;
    }
    if (password.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите пароль");
      return;
    }
    try {
      setLoading(true);
      const data = await authAPI.login(email, password);

      if (data.statusText === "OK") {
        const authData = data.data;
        Cookies.set("authData", JSON.stringify(authData), { secure: true });
        dispatch(setIsAuth(true));
        dispatch(setAuthData(authData));
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      let errorMessages = [];
      const messages = error.response.data;
      Object.values(messages).forEach((item) => errorMessages.push(item));
      setSnackbarOpen(true);
      setSnackbarText(errorMessages.join(" "));
      setLoading(false);
    }
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.imgContainer}>
          <img alt="logo" src={imgURL} />
        </div>
        <div className={styles.content}>
          <h1>Платежный агент</h1>
          <div className={styles.form}>
            <div className={styles.inputContainer}>
              <span className={styles.spanText}>Вход в личный кабинет</span>
              <input
                className={styles.input}
                placeholder="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                className={styles.input}
                placeholder="Пароль"
                name="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div className={styles.textLink}>
                <button
                  type="button"
                  onClick={() => navigate("/reset-password")}
                >
                  Забыли пароль?
                </button>
              </div>

              <button
                className={styles.buttonLogin}
                type="submit"
                aria-label="Нажмите, чтобы войти в личный кабинет"
              >
                {loading ? (
                  <DotsSpinner auth />
                ) : (
                  <span className={styles.spanText2}>Войти</span>
                )}
              </button>

              <div className={styles.secondTextLink}>
                <button type="button" onClick={() => navigate("/register")}>
                  Регистрация
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default LoginPage;
