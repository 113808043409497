import React, { useContext, useEffect, useState } from "react";
import styles from "./ContractPage.module.scss";
import { Button, Divider } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import MainModal from "../../components/MainModal/MainModal";
import InfoComponent from "../../components/InfoComponent/InfoComponent";
import NewInfoModal from "../../components/NewInfoModal/NewInfoModal";
import { contractAPI } from "../../api/contractAPI";
import { useDispatch, useSelector } from "react-redux";
import ModalsContext from "../../contexts/ModalsContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  addContracts,
  setContract,
  setContractDocTypes,
  setContractId,
  updateContracts,
} from "../../redux/slices/contractSlice";
import BodyHeaderContracts from "../../components/BodyHeader/BodyHeaderContracts/BodyHeaderContracts";
import { ThreeCircles } from "react-loader-spinner";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import SnackbarSuccess from "../../reusables/SnackbarSuccess";
import SnackbarFail from "../../reusables/SnackbarFail";
import { newContractOptions, options } from "../../data/contractPageOptions";

const initialContractData = {
  contract_number: "",
  contract_status: 2,
  comment_client: "",
  agent: null,
  is_vbk: false,
};

const ContractPage = ({
  updateContractStatusAndComment,
  clientComment,
  setClientComment,
  loading,
  setLoading,
}) => {
  const [newContractData, setNewContractData] = useState(initialContractData);

  const [fileLoading, setFileLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState({});

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const contracts = useSelector((state) => state.contract.contracts);
  const contract = useSelector((state) => state.contract.contract);
  const authData = useSelector((state) => state.auth.authData);
  const company = useSelector((state) => state.agent.company);
  const contractStatus = useSelector((state) => state.contract.contractStatus);
  const statuses = useSelector((state) => state.contract.contractStatuses);
  const contractDocTypes = useSelector(
    (state) => state.contract.contractDocTypes
  );

  const dispatch = useDispatch();

  const filteredOptions = contract.is_vbk
    ? options
    : options.filter((option) => option.name !== "vbk");

  const {
    newContractModalOpen,
    handleContractModalOpen,
    handleContractModalClose,
  } = useContext(ModalsContext);

  useEffect(() => {
    const fetchDocTypes = async () => {
      try {
        const response = await contractAPI.get_contract_doc_types(authData);
        if (response.statusText === "OK") {
          dispatch(setContractDocTypes(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocTypes();
  }, [authData, dispatch]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSuccess = () => {
    setSnackbarSuccessOpen(false);
  };

  const handleCloseFail = () => {
    setSnackbarFailOpen(false);
  };

  const handleChangeInputs = (event, field) => {
    let value;
    if (field === "is_vbk") {
      value = event.target.checked;
    } else value = event.target.value;

    setNewContractData({
      ...newContractData,
      [field]: value,
    });
  };

  const handleChangeComment = (event) => {
    const text = event.target.value;
    setClientComment(text);
  };

  const handleFileChange = (event, fileType, fileDocType) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 50000000) {
        setSnackbarFailOpen(true);
        setSnackbarText("Размер файла превышает 50 МБ");
        return;
      }
    }

    const foundDocType = contractDocTypes.find(
      (item) => item.id === fileDocType
    );

    setSelectedFiles({
      ...selectedFiles,
      [fileType]: { file: file, docType: foundDocType },
    });
  };

  const handleFileUpload = async (event) => {
    setFileLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();

    if (file) {
      if (file.size > 50000000) {
        setSnackbarFailOpen(true);
        setSnackbarText("Размер файла превышает 50 МБ");
        setFileLoading(false);
        return;
      }
      try {
        formData.append("contr_file", file);
        formData.append("contract", contract.id);
        formData.append("file_name", file.name);
        formData.append("file_type", file.type);
        formData.append("doc_type", 11);

        const response = await contractAPI.postfile(authData, formData);
        if (response.statusText === "Created") {
          const updatedContract = {
            ...contract,
            files: [...contract.files, response.data],
          };
          dispatch(updateContracts(updatedContract));
          dispatch(setContract(updatedContract));
        }
        setFileLoading(false);
      } catch (err) {
        console.log("Ошибка при загрузке файла:", err);
        setSnackbarFailOpen(true);
        setSnackbarText("Ошибка при загрузке файла");
        setFileLoading(false);
      }
    }
  };

  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleChangeContract = (event) => {
    const selectedId = event.target.value;
    const selectedContract = contracts.find(
      (contract) => contract.id === selectedId
    );
    dispatch(setContract(selectedContract));
    dispatch(setContractId(selectedContract.id));
    updateContractStatusAndComment(selectedContract, statuses);
  };

  const handleCreateContract = async () => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });

    const filesArray = [];

    for (const key of Object.keys(selectedFiles)) {
      const file = selectedFiles[key].file;
      const docType = selectedFiles[key].docType;

      if (file) {
        const base64File = await toBase64(file);
        const filesObj = {
          file_base64: base64File,
          file_name: file.name,
          file_type: file.type,
          doc_type: docType.id,
        };
        filesArray.push(filesObj);
      }
    }

    const dataToDB = {
      ...newContractData,
      files: filesArray,
      agent: company.id,
    };

    if (!newContractData.contract_number) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, заполните номер контракта!");
      return;
    }
    if (!selectedFiles.contract) {
      if (!selectedFiles.contract) {
        setSnackbarOpen(true);
        setSnackbarText("Пожалуйста, выберите Внешнеэкономический контракт!");
        return;
      }
    }
    if (newContractData.is_vbk && !selectedFiles.vbk) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, выберите ВБК!");
      return;
    }

    try {
      setLoadingNew(true);
      const data = await contractAPI.create_contracts(authData, dataToDB);

      if (data.statusText === "Created") {
        const newContract = data.data;
        dispatch(addContracts(newContract));
        handleContractModalClose();
        dispatch(setContract(newContract));
        dispatch(setContractId(newContract.id));
        updateContractStatusAndComment(newContract, statuses);
        setSelectedFiles({});
        setNewContractData(initialContractData);
      }
      setLoadingNew(false);
    } catch (error) {
      console.log(error.message);
      setLoadingNew(false);
    }
  };

  const handleUpdateContract = async () => {
    let status;

    if (contractStatus) {
      if (contractStatus.id === 2) {
        status = 2;
      } else if (contractStatus.id === 3) {
        status = 3;
      } else if (contractStatus.id === 4) {
        status = 3;
      }
    }

    setLoading(true);
    try {
      const data = await contractAPI.update__contract(
        clientComment,
        status,
        authData,
        contract.id
      );
      if (data.statusText === "OK") {
        const updatedContract = data.data;
        dispatch(setContract(updatedContract));
        updateContractStatusAndComment(updatedContract, statuses);
      }
      setSnackbarSuccessOpen(true);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <BodyHeaderContracts
        chosenOption={contract}
        onChangeFunc={handleChangeContract}
        list={contracts}
        handleOpen={handleContractModalOpen}
        text="Выберите контракт"
        buttonText="Новый контракт"
        contract={contract}
        loading={loading}
      />

      <SnackbarSuccess
        snackbarOpen={snackbarSuccessOpen}
        handleClose={handleCloseSuccess}
        snackbarText="Контракт обновлен"
      />
      <SnackbarFail
        snackbarOpen={snackbarFailOpen}
        handleClose={handleCloseFail}
        snackbarText={snackbarText}
      />
      {loading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(28, 107, 31, 0.7)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : contracts.length > 0 ? (
        <InfoComponent
          text={`Контракт № ${contract.contract_number}`}
          options={filteredOptions}
          contractStatus={contractStatus}
          company={company}
          contract={contract}
          clientComment={clientComment}
          handleChangeComment={handleChangeComment}
          triggerFileInput={triggerFileInput}
          handleFileUpload={handleFileUpload}
          fileLoading={fileLoading}
          contractDocTypes={contractDocTypes}
        />
      ) : (
        <div className={styles.warnText}>
          <AssignmentIcon className={styles.rotatingIcon} />
          <span>Добавьте новый контракт</span>
        </div>
      )}
      <MainModal
        open={newContractModalOpen}
        onClose={handleContractModalClose}
        isContract
      >
        <div className={styles.modalheader}>
          <h2>Новый контракт</h2>
          <div onClick={handleContractModalClose}>
            <IoMdClose size={25} />
          </div>
        </div>
        <Divider />
        <NewInfoModal
          options={newContractOptions}
          newContract
          selectedFiles={selectedFiles}
          handleFileChange={handleFileChange}
          handleChangeInputs={handleChangeInputs}
          triggerFileInput={triggerFileInput}
          newData={newContractData}
        />
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(38, 45, 137, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(38, 45, 137, 0.7)",
              },
            }}
            onClick={handleCreateContract}
          >
            {loadingNew ? <DotsSpinner /> : "Добавить"}
          </Button>
        </div>
      </MainModal>
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
      {contracts.length > 0 && (
        <div className={styles.btnContainer}>
          <Button
            variant="contained"
            sx={{
              minWidth: "200px",
              minHeight: "45px",
              backgroundColor: "rgba(28, 107, 31, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(28, 107, 31, 0.8)",
              },
            }}
            disabled={
              (contractStatus?.id === 5 ||
                contractStatus?.id === 6 ||
                contractStatus?.id === 7) &&
              true
            }
            onClick={handleUpdateContract}
          >
            {loading ? <DotsSpinner /> : "Обновить"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContractPage;
